import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Icon } from "@iconify/react";
import _ from "lodash";
import { motion } from "framer-motion";
import { TableId } from "../../constants/TableIdConstants";
import { useAuthStore, useCompanyStore } from "../../store";
import { FilterConditionModel } from "../../api/AgGridCache";
import {
  useDeleteTableState,
  useGetTableFilterState,
  usePostTableFilterState,
} from "../../queries/AgGridCacheQueries";
import { useGetUserCompanies } from "../../queries";

interface SwitchProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
}
const Switch: React.FC<SwitchProps> = ({ checked, onChange }) => (
  <motion.div
    className={`w-12 h-6 flex items-center rounded-full p-1 cursor-pointer ${
      checked ? "bg-orange-500" : "bg-gray-300"
    }`}
    onClick={() => onChange(!checked)}
    animate={{ backgroundColor: checked ? "#f97316" : "#D1D5DB" }}
  >
    <motion.div
      className="w-4 h-4 bg-white rounded-full shadow-md"
      animate={{
        x: checked ? 22 : 2,
      }}
      transition={{ type: "spring", stiffness: 700, damping: 30 }}
    />
  </motion.div>
);
interface SavingStatusIconProps {
  isLoading: boolean;
}
const SavingStatusIcon: React.FC<SavingStatusIconProps> = ({ isLoading }) => {
  const [showSaved, setShowSaved] = useState<boolean>(false);
  useEffect(() => {
    let timer: any;
    if (!isLoading) {
      timer = setTimeout(() => {
        setShowSaved(true);
        setTimeout(() => setShowSaved(false), 2000);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [isLoading]);
  return (
    <div className="flex items-center space-x-2">
      <div
        className={`transition-all duration-300 ${
          isLoading ? "animate-spin" : ""
        }`}
      >
        <Icon
          icon={isLoading ? "tdesign:refresh" : "basil:cloud-check-outline"}
          className={`${
            isLoading ? "text-blue-500 w-6 h-6" : "text-green-500 w-6 h-6"
          }`}
        />
      </div>
      <span
        className={`transition-opacity whitespace-nowrap duration-300 ${
          isLoading || showSaved ? "opacity-100" : "opacity-0"
        }`}
      >
        {isLoading ? "Saving..." : showSaved ? "Saved" : ""}
      </span>
    </div>
  );
};
interface CloudSettingProps {
  tableId: string;
  isLoading: boolean;
  filterCondition: FilterConditionModel;
  setFilterCondition: (func: any) => void;
}

const CloudSetting: React.FC<CloudSettingProps> = ({
  tableId,
  isLoading,
  filterCondition,
  setFilterCondition,
}) => {
  const [show, setShown] = useState<boolean>(false);
  const { mutate: deleteTableState } = useDeleteTableState();
  const { companyData } = useCompanyStore();
  const companyIdget = companyData?.id || "";
  const { data: tableState } = useGetTableFilterState(tableId, companyIdget);
  const { mutate: postOnFilter } = usePostTableFilterState();
  const debouncedSearch = useCallback(
    _.debounce((isColumnSave: boolean, isFilterSave: boolean) => {
      const companyId = companyData?.id;
      if (companyId && tableId) {
        postOnFilter({
          tableId,
          companyId,
          filterData: {
            isColumnSave,
            isFilterSave,
            filterState: tableState?.filterState,
            columnState: tableState?.columnState,
          },
        });
      }
    }, 5000),
    [filterCondition, tableState, postOnFilter, companyData?.id]
  );
  const handleMouseEvents = (isShown: boolean) => () => setShown(isShown);
  const handleColumnViewChange = (): void => {
    const newColumnSave = !filterCondition.isColumnSave;
    debouncedSearch(newColumnSave, filterCondition.isFilterSave);
    setFilterCondition((prev: any) => ({
      ...prev,
      isColumnSave: newColumnSave,
    }));
  };
  const handleFilterViewChange = (): void => {
    const newFilterSave = !filterCondition.isFilterSave;
    debouncedSearch(filterCondition.isColumnSave, newFilterSave);
    setFilterCondition((prev: any) => ({
      ...prev,
      isFilterSave: newFilterSave,
    }));
  };
  const handleClearAllStates = (): void => {
    deleteTableState(
      { tableId, companyId: companyIdget },
      {
        onSuccess: () => {
          window.location.reload();
        },
      }
    );
  };
  return (
    <Popover className="relative">
      <Popover.Button
        onClick={handleMouseEvents(!show)}
        onMouseEnter={handleMouseEvents(true)}
        onMouseLeave={handleMouseEvents(false)}
        className={`-m-2.5 p-2.5 ${
          show ? "text-gray-700" : "text-gray-400 hover:text-gray-500"
        } ring-0 border-0 focus:ring-0 border-white`}
      >
        <span className="sr-only">Settings</span>
        <SavingStatusIcon isLoading={isLoading} />
      </Popover.Button>
      <Transition
        show={show}
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel
          onMouseEnter={handleMouseEvents(true)}
          onMouseLeave={handleMouseEvents(false)}
          className="absolute z-10 mt-5 flex w-screen max-w-max -right[270px]"
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
            className="overflow-hidden border border-blue-200 rounded-lg shadow-lg w-80 bg-gradient-to-br from-blue-50 to-purple-50"
          >
            <div className="flex items-center p-4 space-x-2 bg-gradient-to-l from-gradientTo to-gradientFrom">
              <Icon
                icon="mdi:table-cog"
                className="text-white"
                width="24"
                height="24"
              />
              <div>
                <h2 className="text-lg font-bold text-white">
                  Table State Control
                </h2>
                <p className="text-xs text-blue-100">Manage view preferences</p>
              </div>
            </div>
            <div className="p-5 space-y-4">
              {/* <motion.div
                whileHover={{ scale: 1.02 }}
                className="p-3 bg-white rounded-lg shadow-sm"
              >
                <div className="flex items-center justify-between mb-2">
                  <span className="font-medium text-gray-700">Column View</span>
                  <Switch
                    checked={filterCondition.isColumnSave}
                    onChange={handleColumnViewChange}
                  />
                </div>
                <p className="text-xs text-gray-500">
                  Save your custom column layouts
                </p>
              </motion.div> */}
              {/* <motion.div
                whileHover={{ scale: 1.02 }}
                className="p-3 bg-white rounded-lg shadow-sm"
              >
                <div className="flex items-center justify-between mb-2">
                  <span className="font-medium text-gray-700">Filter View</span>
                  <Switch
                    checked={filterCondition.isFilterSave}
                    onChange={handleFilterViewChange}
                  />
                </div>
                <p className="text-xs text-gray-500">
                  Preserve your applied filters
                </p>
              </motion.div> */}
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleClearAllStates}
                className="flex items-center justify-center w-full px-4 py-2 space-x-2 font-medium text-white transition duration-300 ease-in-out bg-orange-500 rounded-lg shadow-sm hover:bg-orange-600"
              >
                <Icon icon="mdi:trash-can-outline" width="16" height="16" />
                <span>Clear All Saved States</span>
              </motion.button>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
                className="flex items-start p-3 mt-4 space-x-2 text-xs text-gray-600 bg-blue-100 border border-blue-200 rounded-lg"
              >
                <Icon
                  icon="mdi:cloud-sync"
                  className="text-blue-500 flex-shrink-0 mt-0.5"
                  width="16"
                  height="16"
                />
                <p>
                  Your preferences are automatically saved in the cloud for
                  consistent views across all your devices.
                </p>
              </motion.div>
            </div>
          </motion.div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
export default CloudSetting;
