import React, { useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { ColDef, GridApi } from "ag-grid-enterprise";
import { Dialog } from "@mui/material";
import { fontSize, fontWeight } from "@mui/system";
import {
  Box,
  ButtonPrimary1,
  HStack,
  IconCTAButton,
  VStack,
} from "../../components/utils";
import SearchInput from "../../components/shared/SearchInput";
import { ReportNames } from "./Reports";
import { sortComparator } from "../../utils/agGridUtils";
import ReportDialog from "./dialogs/ReportDialog";
import PoolStatementDialog from "./dialogs/PoolStatementDialog";
import SH6ReportDialog from "../esopOverview/SH6ReportDialog";
import FinancialReportDialog from "../esopOverview/FinancialReportDialog";

const ReportsAgGridTable = ({
  reportsData,
}: {
  reportsData: ReportNames[];
}) => {
  const [dialog, setDialog] = useState<{
    open: boolean;
    data: any;
  }>({ open: false, data: {} });
  const gridApi = useRef<GridApi | any>(null);
  const generateButton = (params: any) => (
    <ButtonPrimary1
      onClick={() => {
        setDialog({ open: true, data: params.data });
      }}
    >
      Generate Report
    </ButtonPrimary1>
  );
  const sortComparator = (
    valueA: any,
    valueB: any,
    nodeA: any,
    nodeB: any,
    isInverted: any
  ) => {
    if (valueA === valueB) return 0;
    return valueA > valueB ? 1 : -1;
  };
  const columnDefs: ColDef[] = [
    {
      headerName: "Name of Document",
      field: "name",
      cellStyle: (params: any) => ({
        fontWeight: 500,
      }),
      flex: 2,
    },
    {
      headerName: "Description of document",
      field: "description",
      flex: 6.5,
    },
    {
      headerName: "Generate Report",
      field: "actions",
      cellRendererParams: ({ value }: { value: any }) => value,
      cellRenderer: generateButton,
      flex: 1.5,
    },
  ];

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      wrapText: true,
      flex: 1,
      autoHeight: true,
      initialWidth: 100,
      suppressMenu: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      cellClass: "multiline",
      comparator: sortComparator,
      minWidth: 100,
      resizable: true,
    }),
    []
  );

  return (
    <VStack className="w-full h-full">
      <HStack className="items-center justify-end gap-4">
        <HStack>
          <SearchInput
            className=""
            placeholder={`Search`}
            onChange={(e: any) => {
              gridApi.current.setQuickFilter(e.target.value);
            }}
          />
        </HStack>
      </HStack>
      <HStack className="justify-between w-full">
        <Box
          style={{
            height: `${reportsData.length * 60}px`,
          }}
          className="w-full h-full max-h-full overflow-x-auto bg-black ag-theme-material"
        >
          <AgGridReact
            onGridReady={(params) => {
              gridApi.current = params.api;
            }}
            animateRows={true}
            defaultColDef={defaultColDef}
            rowData={reportsData}
            columnDefs={columnDefs}
            suppressRowTransform={true}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={false}
            rowClass={
              "border-t border-dashed cursor-pointer hover:bg-slate-50 "
            }
          ></AgGridReact>
        </Box>
      </HStack>
      <Dialog
        open={dialog.open}
        onClose={() => setDialog({ open: false, data: undefined })}
        maxWidth="lg"
      >
        {dialog.data?.name === "Pool Statement" ? (
          <div className="w-[400px] mx-auto bg-white rounded-lg  min-h-min">
            <PoolStatementDialog
              data={dialog.data}
              onClose={() => setDialog({ open: false, data: undefined })}
            />
          </div>
        ) : dialog.data?.name === "SH-6 Report" ? (
          <div className="mx-auto bg-white rounded-lg min-w-min min-h-min">
            <SH6ReportDialog
              onClose={() => setDialog({ open: false, data: undefined })}
            />
          </div>
        ) : (
          <div className="w-[800px] mx-auto bg-white rounded-lg  min-h-min">
            <ReportDialog
              data={dialog.data}
              onClose={() => setDialog({ open: false, data: undefined })}
            />
          </div>
        )}
      </Dialog>
    </VStack>
  );
};

export default ReportsAgGridTable;
