import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { Icon } from "@iconify/react";
import { Dialog } from "@mui/material";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  HStack,
} from "../../../components/utils";

import { useOrgTemplateStore } from "../../../store/useOrgTemplateStore";
import { CompensationModellingData } from "../companyDetails/utils/OrgStructureJSONDataProc";
import AllocationMethod from "./AllocationMethod";
import GeneralCompModelling from "./GeneralCompModelling";
import KeyRolesCompModelling from "./KeyRolesCompModelling";
import CompModellingValuationDetails from "./CompModellingValuationDetails";
import CompModellingSummary from "./CompModellingSummary";
import { useCreateCompensationModelling } from "../../../queries/compensationModelling";
import AlertDialog from "../../../components/shared/AlertDialog";
import KeyPersonnelCompModelling from "./KeyPersonnelCompModelling";
import CompModellingSummaryWithEmp from "./CompModellingSummaryWithEmp";

type CompensationModellingNavigationProps = {
  currentStep: number;
  onStepChange: () => void;
  onBackClick: () => void;
};
export default function CompensationModellingNavigation(
  props: Readonly<CompensationModellingNavigationProps>
) {
  const navigate = useNavigate();
  const [prevData, setPrevData] = useState<CompensationModellingData>();
  const { compensationModellingData, clear } = useOrgTemplateStore();
  const { mutate: createCompModelling, isLoading } =
    useCreateCompensationModelling();
  function handleSave() {
    if (
      compensationModellingData &&
      JSON.stringify(prevData) !== JSON.stringify(compensationModellingData)
    ) {
      createCompModelling(compensationModellingData, {
        onSuccess: () => {
          toast("Successfully Created Compensation Modelling", {
            type: "success",
            autoClose: 2000,
          });
          navigate(`/modelling/home`);
        },
        onError: () => {
          toast("Error while creating Compensation Modelling", {
            type: "error",
            autoClose: 2000,
          });
        },
      });
    }
  }
  const { currentStep, onStepChange, onBackClick } = props;
  const [dialog, setDialog] = useState<{
    open: boolean;
  }>({ open: false });
  return (
    <Box className="w-full h-full pb-4 mx-auto align-middle">
      <HStack>
        {currentStep === 1 && <AllocationMethod />}
        {(currentStep === 2 || currentStep === 2.1) && <GeneralCompModelling />}
        {currentStep === 2.2 && <KeyRolesCompModelling />}
        {currentStep === 2.3 && <KeyPersonnelCompModelling />}
        {currentStep === 3 && <CompModellingValuationDetails />}
        {currentStep === 4 &&
          !compensationModellingData?.data?.employeeListUploaded && (
            <CompModellingSummary />
          )}
        {currentStep === 4 &&
          compensationModellingData?.data?.employeeListUploaded && (
            <CompModellingSummaryWithEmp />
          )}
      </HStack>
      <Dialog
        open={dialog.open}
        onClose={() => setDialog({ open: false })}
        maxWidth="lg"
      >
        <AlertDialog
          onClose={() => setDialog({ open: false })}
          open={dialog.open}
          message="Are you sure you want to Clear all data"
          onPrimaryAction={() => {
            clear();
            setDialog({ open: false });
            navigate(`/modelling/home`);
          }}
          onSecondaryAction={() => setDialog({ open: false })}
        />
      </Dialog>
      {currentStep === 4 ? (
        <HStack className="justify-between px-4 pt-4 pl-8">
          <ButtonPrimary1 onClick={() => setDialog({ open: true })}>
            Cancel
          </ButtonPrimary1>
          <ButtonPrimary
            loading={isLoading}
            loadingText="Saving.."
            onClick={() => handleSave()}
          >
            Save
          </ButtonPrimary>
        </HStack>
      ) : (
        <HStack className="justify-between px-4 pt-4 pl-8">
          <ButtonPrimary1
            onClick={() =>
              currentStep === 1 ? navigate(`/modelling/home`) : onBackClick()
            }
          >
            Back
          </ButtonPrimary1>
          <ButtonPrimary onClick={onStepChange}>Next</ButtonPrimary>
        </HStack>
      )}
    </Box>
  );
}
