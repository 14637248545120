import {
  CellClickedEvent,
  ColDef,
  GridApi,
  SideBarDef,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import _ from "lodash";
import { useCallback, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import SearchInput from "../../components/shared/SearchInput";
import {
  Box,
  HStack,
  IconCTAButton,
  VStack,
  isAdminViewer,
  isEsopViewer,
} from "../../components/utils";
import { useAuthorizationStore } from "../../store/useAuthorizationStore";
import { useVestingScheduleDialog } from "../../store/useDialogStore";
import {
  TriggerType,
  VestingTemplate,
  VestingType,
} from "../../types/VestingTemplate";
import { getCurrencyType } from "../../utils/currencyFormatter";
import { stringToNumberConvertor } from "../../utils/string";
import { TableId } from "../../constants/TableIdConstants";
import { useTableStateManagement } from "../../components/shared/TableHook";
import CloudSetting from "../../components/shared/CloudIcon";

function VestingTemplatesTableAgGrid({
  VestingTemplateTableData,
  handleAction,
}: {
  VestingTemplateTableData: VestingTemplate[];
  handleAction: (template: VestingTemplate, action: Action) => void;
}) {
  const isUserAdminViewer = isAdminViewer();
  const isUserEsopViewer = isEsopViewer();
  const { authority } = useAuthorizationStore();
  const { setState: setDialog } = useVestingScheduleDialog();
  const chartThemes = useMemo<string[]>(() => ["ag-pastel", "ag-vivid"], []);
  const navigate = useNavigate();
  const currency = getCurrencyType();
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const gridApi = useRef<GridApi | any>(null);
  const sideBar = useMemo<SideBarDef>(
    () => ({
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          width: 225,
          maxWidth: 225,
          toolPanelParams: {
            suppressRowGroups: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressValues: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
      ],
    }),
    []
  );
  const {
    filterCondition,
    setFilterCondition,
    isSaving,
    uploadFilter,
    uploadColumn,
  } = useTableStateManagement(gridApi, TableId.vestingTable);

  const isValidDate = (date: string) =>
    Number.isNaN(date) &&
    new Date(date).toString() !== "Invalid Date" &&
    !Number.isNaN(Date.parse(date));

  const normaliseValue = (value: string | number) => {
    if (typeof value === "number") return value;
    if (typeof value === "string") {
      // check if it can be converted to number first
      // assume if commas are there
      if (isValidDate(value)) return new Date(value).valueOf();
      try {
        value = value.replaceAll(",", "");
      } catch (TypeError) {
        value = value.replace(/,./g, "");
      }
      return parseFloat(value);
    }
    return value;
  };
  const sortComparator = (
    valueA: number | string,
    valueB: number | string,
    ..._: any[]
  ) => normaliseValue(valueA) - normaliseValue(valueB);
  const filterparams = {
    filterParams: {
      filterOptions: [
        "lessThan",
        {
          displayKey: "lessThanWithNulls",
          displayName: "Less Than with Nulls",
          predicate: ([filterValue]: any, cellValue: number | null) =>
            cellValue == null || cellValue < filterValue,
        },
        "greaterThan",
        {
          displayKey: "greaterThanWithNulls",
          displayName: "Greater Than with Nulls",
          predicate: ([filterValue]: any, cellValue: number | null) =>
            cellValue == null || cellValue > filterValue,
        },
        {
          displayKey: "betweenExclusive",
          displayName: "Between (Exclusive)",
          predicate: ([fv1, fv2]: any, cellValue: number | null) =>
            cellValue == null ||
            // eslint-disable-next-line no-mixed-operators
            (fv1 < cellValue && fv2 > cellValue),
          numberOfInputs: 2,
        },
      ],
    },
  };
  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      wrapText: true,
      flex: 1,
      autoHeight: true,
      initialWidth: 150,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      cellClass: "multiline",
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      comparator: sortComparator,
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
    }),
    []
  );

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "TEMPLATE NAME",
        field: "vestingTemplateName",
        cellStyle: { paddingTop: "15px", lineHeight: "20px" },
        filter: "agSetColumnFilter",
        filterParams: {
          comparator: sortComparator,
          defaultToNothingSelected: true,
          suppressSelectAll: false,
          suppressMiniFilter: false,
          excelMode: "windows",
          buttons: ["reset"],
        },
        filterValueGetter: (params) => params.getValue("vestingTemplateName"),
        comparator(valueA, valueB, ..._other) {
          return valueA.localeCompare(valueB);
        },
        sortable: true,
        suppressSizeToFit: true,
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: "OPTION HOLDERS",
        field: "numberOfGrantsAffected",
        filter: "agNumberColumnFilter",
        filterValueGetter: (params) =>
          stringToNumberConvertor(params.getValue("numberOfGrantsAffected")),
        filterParams: filterparams,
        menuTabs: ["filterMenuTab"],
        sortable: true,
      },
      {
        headerName: "VESTING TYPE",
        field: "vestingType",
        filter: "agSetColumnFilter",
        filterValueGetter: (params) => params.getValue("vestingType"),
        filterParams: {
          comparator: sortComparator,
          defaultToNothingSelected: true,
          suppressSelectAll: false,
          suppressMiniFilter: false,
          excelMode: "windows",
          buttons: ["reset"],
        },
        comparator(valueA, valueB, ..._other) {
          return valueA?.localeCompare(valueB);
        },
        sortable: true,
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: "TRIGGERS",
        field: "vestingTriggerType",
        filter: "agSetColumnFilter",
        filterParams: {
          comparator: sortComparator,
          defaultToNothingSelected: true,
          suppressSelectAll: false,
          suppressMiniFilter: false,
          excelMode: "windows",
          buttons: ["reset"],
        },
        comparator(valueA, valueB, ..._other) {
          return valueA?.localeCompare(valueB);
        },
        filterValueGetter: (params) => params.getValue("vestingTriggerType"),
        sortable: true,
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: "VESTING SCHEDULES",
        field: "vestingSchedule",
        cellStyle: { paddingTop: "15px", lineHeight: "0px" },
        filter: false,
        menuTabs: [],
        cellRendererParams: ({ value }: { value: { props: any } }) =>
          value.props,
        cellRenderer: VestingInfoRender,
      },
      {
        headerName: "",
        field: "actions",
        pinned: "right",
        hide: false,
        width: 80,
        maxWidth: 80,
        filter: false,
        colId: "action-column",
        suppressNavigable: true,
        suppressColumnsToolPanel: true,
        resizable: false,
        sortable: false,
        menuTabs: ["columnsMenuTab"],
        cellRendererParams: ({ value }: { value: any }) => value.props,
        cellRenderer: CTADropdown,
      },
    ],
    []
  );

  function getVestingDetails(vesting: VestingTemplate) {
    let vestingSchedule = "";
    if (vesting.vestingType === VestingType.STANDARD) {
      if (vesting.schedules.length > 0) {
        const totalPeriod = vesting.schedules[0].vestingDuration;
        const interval = vesting.schedules[0].vestingInterval;
        vestingSchedule = `${totalPeriod} Months Vesting every ${interval} Months, ${vesting.cliffPeriod} Months Cliff`;
      }
    } else {
      const isBoth =
        vesting.schedules.some(
          (sc) => sc.vestingTriggerType === TriggerType.TIME
        ) &&
        vesting.schedules.some(
          (sc) => sc.vestingTriggerType === TriggerType.EVENT
        );
      const isTime =
        vesting.schedules.some(
          (sc) => sc.vestingTriggerType === TriggerType.TIME
        ) &&
        !vesting.schedules.some(
          (sc) => sc.vestingTriggerType === TriggerType.EVENT
        );
      const isEvent =
        !vesting.schedules.some(
          (sc) => sc.vestingTriggerType === TriggerType.TIME
        ) &&
        vesting.schedules.some(
          (sc) => sc.vestingTriggerType === TriggerType.EVENT
        );
      for (const schedule of vesting.schedules) {
        if (isTime) {
          vestingSchedule += `${schedule.percentage}% ${schedule.vestingDuration} Months Vesting every ${schedule.vestingInterval} Months, ${vesting.cliffPeriod} Months Cliff`;
        } else if (isEvent) {
          vestingSchedule = `Event based Vesting, ${vesting.cliffPeriod} Months Cliff`;
        } else if (isBoth) {
          const totalTimePercentage = _.sumBy(
            vesting.schedules.filter(
              (sc) => sc.vestingTriggerType === TriggerType.TIME
            ),
            (sc) => sc.percentage
          );
          const totalEventPercentage = _.sumBy(
            vesting.schedules.filter(
              (sc) => sc.vestingTriggerType === TriggerType.EVENT
            ),
            (sc) => sc.percentage
          );
          const timeAndCustomSchedule =
            totalEventPercentage === 0 && totalTimePercentage > 0
              ? `Custom Time based Vesting, ${vesting.cliffPeriod} Months Cliff`
              : `${totalEventPercentage}% Event based Vesting and ${totalTimePercentage}% Time based Vesting with ${vesting.cliffPeriod} Months Cliff`;
          vestingSchedule =
            totalEventPercentage > 0 && totalTimePercentage === 0
              ? `Event based Vesting, ${vesting.cliffPeriod} Months Cliff`
              : timeAndCustomSchedule;
        }
      }
    }
    return vestingSchedule;
  }
  function getVestingTriggerType(vesting: VestingTemplate) {
    let vestingTriggerType: TriggerType =
      vesting.includeTimeVesting && !vesting.includeEventVesting
        ? TriggerType.TIME
        : TriggerType.EVENT;
    vestingTriggerType =
      vesting.includeTimeVesting && vesting.includeEventVesting
        ? TriggerType.BOTH
        : vestingTriggerType;
    vestingTriggerType =
      !vesting.includeTimeVesting && !vesting.includeEventVesting
        ? TriggerType.TIME
        : vestingTriggerType;
    return vestingTriggerType;
  }

  const rowData = useMemo(
    () =>
      VestingTemplateTableData?.map((template) => ({
        vestingTemplateName: template.vestingTemplateName,
        numberOfGrantsAffected: template.numberOfGrantsAffected,
        vestingType: template.vestingType,
        vestingTriggerType: getVestingTriggerType(template),
        vestingSchedule: (
          <VestingInfoRender vestingDetails={getVestingDetails(template)} />
        ),
        actions: (
          <CTADropdown
            actions={[
              {
                name: "Edit",
                disabled:
                  template.numberOfGrantsAffected > 0 ||
                  isUserAdminViewer ||
                  isUserEsopViewer,
              },
            ]}
            onAction={(action) => handleAction(template, action)}
          />
        ),
      })),
    [VestingTemplateTableData]
  );

  const onPageSizeChanged = useCallback(() => {
    const value = (document.getElementById("page-size") as HTMLInputElement)
      .value;
    gridApi.current?.api.paginationSetPageSize(Number(value));
  }, []);

  const handleCellClick = (cellParams: CellClickedEvent<any, any>) => {
    if (cellParams.column.getColId() !== "action-column") {
      const selectedRowIndex = cellParams.node.rowIndex || 0;
      const template = VestingTemplateTableData[selectedRowIndex];
      setDialog({ open: true, template, mode: "View" });
    }
  };
  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api.openToolPanel(key);
        else gridApi?.current?.api.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) gridApi?.current?.api.openToolPanel(key);
        else gridApi?.current?.api.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };
  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    let filterModel = grid.api.getFilterModel();
    const isOnlyEmptySetFilters =
      Object.keys(filterModel).length > 0 &&
      Object.values(filterModel).every(
        (filter: any) =>
          filter.filterType === "set" &&
          Array.isArray(filter.values) &&
          filter.values.length === 0
      );

    if (isOnlyEmptySetFilters) {
      grid.api.setFilterModel(null);
      filterModel = {};
    }

    setIsFilterApplied(filtersApplied);
    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
    uploadFilter(filterModel);
  };
  return (
    <VStack className="w-full">
      <HStack className="pl-6 p-0 m-0">
        <CloudSetting
          tableId={TableId.vestingTable}
          isLoading={isSaving}
          filterCondition={filterCondition}
          setFilterCondition={setFilterCondition}
        />
      </HStack>
      <HStack className="items-center justify-end gap-4">
        <HStack>
          <SearchInput
            className=""
            placeholder={`Search`}
            onChange={(e: any) => {
              gridApi.current.api.setQuickFilter(e.target.value);
            }}
          />
        </HStack>
        <IconCTAButton
          value={"Columns"}
          onClick={() => openToolPanel("columns")}
          iconName={"fluent:column-triple-edit-20-regular"}
          className={`px-4 font-medium items-center flex flex-row ${
            isColumnOpen ? "text-orange-501" : "text-gray-400"
          }`}
          selected={isColumnOpen}
        />
        <IconCTAButton
          value={"Filters"}
          onClick={() => openToolPanel("filters")}
          iconName={"material-symbols:filter-alt"}
          className={`px-4  font-medium items-center flex flex-row ${
            isFilterOpen ? "text-orange-501" : "text-gray-400"
          }`}
          selected={isFilterOpen}
        />
      </HStack>
      <HStack className="justify-between w-full">
        <Box
          style={{
            height: `${
              (rowData.length >= 10 ? 10 : rowData.length + 3) * 60
            }px`,
          }}
          className="w-full h-full max-h-full overflow-x-auto bg-black ag-theme-material"
        >
          <AgGridReact
            sideBar={sideBar}
            onGridReady={(params) => {
              gridApi.current = params;
            }}
            onFilterChanged={onAgGridFilterChanged}
            onColumnEverythingChanged={uploadColumn}
            onColumnResized={uploadColumn}
            alwaysShowHorizontalScroll
            alwaysMultiSort
            animateRows={true}
            defaultColDef={defaultColDef}
            rowData={rowData}
            onCellClicked={handleCellClick}
            columnDefs={columnDefs}
            pagination={true}
            suppressRowTransform={true}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={true}
            rowClass={
              "border-t border-dashed cursor-pointer hover:bg-slate-50 "
            }
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;   margin-top: 50px;">No Rows To Show</span>'
            }
          ></AgGridReact>
        </Box>
      </HStack>
    </VStack>
  );
}

function VestingInfoRender(props: { vestingDetails: string }) {
  return (
    <Box>
      <p className={`text-xs font-medium text-gray-dark `}>
        {props.vestingDetails}
      </p>
    </Box>
  );
}

export default VestingTemplatesTableAgGrid;
