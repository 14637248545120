import { useMutation, useQuery } from "react-query";
import {
  getAllUser,
  getCCEmailSettings,
  getESignSettings,
  getEmailSettings,
  getEmailTemplateContent,
  getEmailTemplateNames,
  getEmployeePortalSettings,
  getGrantLetterSettings,
  listEmailTemplates,
  saveEmailTemplateContent,
  setCCEmailSettings,
  setDeleteDefaultSignature,
  setESginSettingDetails,
  setEmailSettingDetails,
  setEmployeePortalSettingDetails,
  setGrantLetterSettingDetails,
} from "../api/siteSettings";
import { queryClient } from "./client";

export function useGetEmployeePortalSettings() {
  return useQuery({
    queryKey: "getEmployeePortalSettings",
    queryFn: getEmployeePortalSettings,
  });
}
export function useGetEmailSettings() {
  return useQuery({
    queryKey: "getEmailSettings",
    queryFn: getEmailSettings,
  });
}

export function useGetGrantLetterSettings() {
  return useQuery({
    queryKey: "getGrantLetterSettings",
    queryFn: getGrantLetterSettings,
    staleTime: 10000 * 60,
    cacheTime: 10000 * 60,
  });
}
export function useESginSettings() {
  return useQuery({
    queryKey: "getESignSettings",
    queryFn: getESignSettings,
  });
}
export function useSetEmployeePortalSettings() {
  return useMutation({
    mutationKey: "setEmployeePortalSettingDetails",
    mutationFn: setEmployeePortalSettingDetails,
    onSuccess: () => {
      queryClient.invalidateQueries("getEmployeePortalSettings");
      queryClient.refetchQueries("getEmployeePortalSettings");
    },
  });
}

export function useSetEmailSettings() {
  return useMutation({
    mutationKey: "setEmailSettingDetails",
    mutationFn: setEmailSettingDetails,
    onSuccess: () => {
      queryClient.invalidateQueries("getEmailSettings");
      queryClient.refetchQueries("getEmailSettings");
    },
  });
}
export function useSetGrantLetterSettings() {
  return useMutation({
    mutationKey: "setGrantLetterSettingDetails",
    mutationFn: setGrantLetterSettingDetails,
    onSuccess: () => {
      queryClient.invalidateQueries("getGrantLetterSettings");
      queryClient.refetchQueries("getGrantLetterSettings");
    },
  });
}
export function useDeleteDefaultSignature() {
  return useMutation({
    mutationFn: setDeleteDefaultSignature,
    mutationKey: "setDeleteDefaultSignature",
    onSuccess: () => {
      queryClient.invalidateQueries("getGrantLetterSettings");
      queryClient.refetchQueries("getGrantLetterSettings");
    },
  });
}

export function useSetESignSettings() {
  return useMutation({
    mutationKey: "setESginSettingDetails",
    mutationFn: setESginSettingDetails,
    onSuccess: () => {
      queryClient.invalidateQueries("getESignSettings");
      queryClient.refetchQueries("getESignSettings");
    },
  });
}
export function useGetEmailTemplateNames() {
  return useQuery({
    queryKey: "emailTemplateNames",
    queryFn: listEmailTemplates,
  });
}
export function useGetEmailTemplateContent(templateId: string) {
  return useQuery({
    queryKey: ["emailTemplate", templateId],
    queryFn: getEmailTemplateContent,
    enabled: false,
  });
}

export function useSaveEmailTemplate() {
  return useMutation({
    mutationKey: "saveEmailTemplate",
    mutationFn: saveEmailTemplateContent,
  });
}

export function useGetAllCompanyUsers() {
  return useQuery({
    queryKey: "allCompanyUsers",
    queryFn: getAllUser,
  });
}

export function useSaveEmailCCSettings() {
  return useMutation({
    mutationKey: "saveEmailCCSettings",
    mutationFn: setCCEmailSettings,
    onSuccess: () => {
      queryClient.invalidateQueries("getEmailCCSettings");
      queryClient.refetchQueries("getEmailCCSettings");
    },
  });
}

export function useGetEmailCCSettings() {
  return useQuery({
    queryKey: "getEmailCCSettings",
    queryFn: getCCEmailSettings,
  });
}
