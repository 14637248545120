import { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import { FilterConditionModel } from "../../api/AgGridCache";
import {
  useGetTableFilterState,
  usePostTableFilterState,
} from "../../queries/AgGridCacheQueries";
import { useGetUserCompanies } from "../../queries";
import { useCompanyStore } from "../../store";

export const useTableStateManagement = (gridApi: any, tableId: string) => {
  const [currentFilterSetting, setCurrentFilterSetting] = useState("");
  const [currentColumnSetting, setCurrentColumnSetting] = useState("");
  const [filterCondition, setFilterCondition] = useState<FilterConditionModel>({
    isColumnSave: true,
    isFilterSave: true,
  });
  const { companyData } = useCompanyStore();
  const [isSaving, setIsSaving] = useState(false);
  const {
    data: tableState,
    refetch,
    isLoading,
  } = useGetTableFilterState(tableId, companyData?.id || "");

  useEffect(() => {
    refetch();
  }, [companyData?.id]);

  const { mutate: postOnFilter } = usePostTableFilterState();

  useEffect(() => {
    if (!isLoading && tableState) {
      const { filterState, columnState, isColumnSave, isFilterSave } =
        tableState;
      setCurrentFilterSetting(JSON.stringify(filterState));
      setCurrentColumnSetting(JSON.stringify(columnState));
      setFilterCondition({
        isColumnSave: isColumnSave ?? true,
        isFilterSave: isFilterSave ?? true,
      });

      if (columnState !== "") {
        setColumnSetting(columnState);
      }
      if (filterState !== "") {
        setFilterSetting(filterState);
      }
    }
  }, [tableState, isLoading]);
  const getColumnSetting = useCallback(() => {
    if (gridApi.current) return gridApi.current.columnApi?.getColumnState();
    else return {};
  }, [gridApi.current, companyData?.id]);

  const setColumnSetting = useCallback(
    (model: Record<string, unknown>): void => {
      if (gridApi.current)
        gridApi.current.columnApi?.applyColumnState({
          state: model,
          applyOrder: true,
        });
    },
    [gridApi.current, companyData?.id]
  );

  const getFilterSetting = useCallback(() => {
    if (gridApi.current) return gridApi.current.api?.getFilterModel();
    else return {};
  }, [gridApi.current, companyData?.id]);

  const setFilterSetting = useCallback(
    (model: Record<string, unknown>): void => {
      if (gridApi.current) gridApi.current.api?.setFilterModel(model);
    },
    [gridApi.current, companyData?.id]
  );

  // const updatedCloud = useCallback(async (): Promise<void> => {
  //   const columnState = await getColumnSetting();
  //   const filterState = await getFilterSetting();
  //   const newColumnState = JSON.stringify(columnState);
  //   const newFilterState = JSON.stringify(filterState);

  //   if (
  //     currentFilterSetting !== newFilterState ||
  //     currentColumnSetting !== newColumnState
  //   ) {
  //     debouncedSearch(filterState, columnState);
  //   }
  // }, [
  //   currentFilterSetting,
  //   currentColumnSetting,
  //   getColumnSetting,
  //   getFilterSetting,
  //   companyData?.id,
  // ]);

  const debouncedSearch = useCallback(
    _.debounce(
      (
        filterState: Record<string, unknown>,
        columnState: Record<string, unknown>
      ) => {
        const companyId = companyData?.id || "";
        // const filteredRows: any = [];
        // gridApi.current?.api.forEachNodeAfterFilter((node: any) => {
        //   filteredRows.push(node.data);
        // });
        // if (filteredRows.length === 0) return;
        setIsSaving(true);
        postOnFilter(
          {
            tableId,
            companyId,
            filterData: {
              isColumnSave: filterCondition.isColumnSave,
              isFilterSave: filterCondition.isFilterSave,
              filterState: filterCondition.isFilterSave
                ? filterState
                : JSON.parse(currentFilterSetting),
              columnState: filterCondition.isColumnSave
                ? columnState
                : JSON.parse(currentColumnSetting),
            },
          },
          {
            onSuccess: () => {
              setTimeout(() => {
                setIsSaving(false);
              }, 2000);
            },
            onError: () => {
              setIsSaving(false);
            },
          }
        );
      },

      3000
    ),
    [
      filterCondition,
      currentFilterSetting,
      currentColumnSetting,
      postOnFilter,
      companyData?.id,
    ]
  );

  const updatedCloud = useCallback(
    (filterModel: any): void => {
      const columnState = getColumnSetting();
      const newFilterState = JSON.stringify(filterModel);
      const newColumnState = JSON.stringify(columnState);

      if (
        currentFilterSetting !== newFilterState ||
        currentColumnSetting !== newColumnState
      ) {
        debouncedSearch(filterModel, columnState);
      }
    },
    [
      currentFilterSetting,
      currentColumnSetting,
      getColumnSetting,
      debouncedSearch,
      companyData?.id,
    ]
  );

  const uploadFilter = useCallback(
    (filterModel: any): void => {
      if (filterCondition.isFilterSave) {
        updatedCloud(filterModel);
      }
    },
    [filterCondition.isFilterSave, updatedCloud, companyData?.id]
  );

  // const uploadColumn = useCallback(async (): Promise<void> => {
  //   if (filterCondition.isColumnSave) await updatedCloud();
  // }, [filterCondition.isColumnSave, updatedCloud, companyData?.id]);

  const uploadColumn = useCallback(async (): Promise<void> => {
    if (filterCondition.isColumnSave) {
      const currentFilterModel = gridApi.current?.api.getFilterModel();
      await updatedCloud(currentFilterModel);
    }
  }, [filterCondition.isColumnSave, updatedCloud, gridApi, companyData?.id]);

  // const uploadFilter = useCallback(async (): Promise<void> => {
  //   if (filterCondition.isFilterSave) {
  //     await updatedCloud();
  //   }
  // }, [filterCondition.isFilterSave, updatedCloud, companyData?.id]);

  return {
    filterCondition,
    setFilterCondition,
    isSaving,
    uploadFilter,
    uploadColumn,
    gridApi,
  };
};
