import { Icon } from "@iconify/react";
import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { usePermissionStore } from "../store/permissionStore";
import { AclList, Action, Resource } from "../utils/interfaces/Companies";

import { determineUserAccessToResource } from "../utils/auth";
import useIsMobile from "../utils/detectDevice";
import { classNames } from "../utils/string";

interface SideNavItemType {
  name: string;
  permissions: AclList;
  icon: any;
  path: string;
  useCurrentPortal?: boolean;
  enabledForEsopOnlyCompany?: boolean;
  disabled?: boolean;
  childSubRoutes?: Array<string>;
}
function MainSideNavbar() {
  const { permission } = usePermissionStore();
  const sideNavItemsMain: Array<SideNavItemType> = [
    {
      name: "Overview",
      permissions: { resource: Resource.All, action: Action.Read },
      icon: "ic:round-dashboard",
      path: "/options/esopOverview",
      enabledForEsopOnlyCompany: true,
      useCurrentPortal: true,
      childSubRoutes: ["add-edit-pool", "plan"],
    },
    {
      name: "Plans & Grants",
      permissions: { resource: Resource.All, action: Action.Read },
      icon: "icon-park-outline:certificate",
      path: "/options/allPlans",
      enabledForEsopOnlyCompany: true,
      useCurrentPortal: true,
      childSubRoutes: ["historic", "planView"],
    },
    {
      name: "Stakeholders",
      permissions: { resource: Resource.All, action: Action.Read },
      icon: "heroicons:user-group-solid",
      path: "/options/allEmployees",
      enabledForEsopOnlyCompany: true,
      useCurrentPortal: true,
      childSubRoutes: ["employees", "employeeDetails"],
    },
    {
      name: "Liquidity & Exercise",
      permissions: { resource: Resource.All, action: Action.All },
      icon: "carbon:flow-connection",
      path: "/options/esopTransactions",
      enabledForEsopOnlyCompany: true,
      useCurrentPortal: true,
      childSubRoutes: [
        "view-option-buy-back",
        "create-option-buyback",
        "edit-options-buyback",
        "exercise-event",
        "complete-buy-back",
        "/create-exercise-liquidation-flow",
        "create-exercise-event",
        "start-buy-back",
      ],
    },
    {
      name: "Vesting Timelines",
      permissions: { resource: Resource.All, action: Action.Read },
      icon: "entypo:bar-graph",
      path: "/options/vestingSchedules",
      enabledForEsopOnlyCompany: true,
      useCurrentPortal: true,
    },
    {
      name: "Milestones",
      permissions: { resource: Resource.All, action: Action.Read },
      icon: "f7:flag-fill",
      path: "/options/milestones",
      enabledForEsopOnlyCompany: true,
      useCurrentPortal: true,
      childSubRoutes: ["milestones"],
    },
    {
      name: "Valuation",
      permissions: { resource: Resource.All, action: Action.Read },
      icon: "mdi:graph-timeline-variant",
      path: "/options/valuationReports",
      enabledForEsopOnlyCompany: true,
      useCurrentPortal: true,
    },
    {
      name: "Documents",
      permissions: { resource: Resource.All, action: Action.All },
      icon: "mdi:file-document-box",
      path: "/options/documents",
      enabledForEsopOnlyCompany: true,
      useCurrentPortal: true,
    },
    {
      name: "Reports",
      permissions: { resource: Resource.All, action: Action.Read },
      icon: "mdi:file-report-outline",
      path: "/options/reports",
      enabledForEsopOnlyCompany: true,
      useCurrentPortal: true,
    },
    {
      // mdi:graph-timeline-variant
      name: "Modelling",
      permissions: { resource: Resource.All, action: Action.All },
      icon: "fluent-mdl2:modeling-view",
      path: "/modelling",
      enabledForEsopOnlyCompany: true,
      useCurrentPortal: true,
      disabled: false,
      childSubRoutes: [
        "home",
        "companyDetails",
        "hiringPlan",
        "compensationModelling",
      ],
    },
    {
      name: "Employee Datasources",
      permissions: { resource: Resource.All, action: Action.All },
      icon: "fluent:people-sync-28-regular",
      path: "/options/employeeSyncs",
      enabledForEsopOnlyCompany: true,
      useCurrentPortal: true,
    },
  ];
  const [routes, setRoutes] = useState(sideNavItemsMain);
  useMemo(() => {
    if (!permission) return;
    const sideNavItems: Array<SideNavItemType> = sideNavItemsMain.filter(
      (sideNavItem) =>
        determineUserAccessToResource(
          permission.aclList,
          sideNavItem.permissions.resource,
          sideNavItem.permissions.action
        )
    );
    setRoutes(sideNavItems);
  }, [permission]);

  const { isMobile } = useIsMobile();
  const [open, setOpen] = useState(false);
  return (
    <div
      onMouseEnter={() => {
        setOpen(true);
      }}
      onMouseLeave={() => {
        setOpen(false);
      }}
      className={`hidden ease-in duration-300 lg:fixed lg:inset-y-0 lg:left-0 lg:z-50 lg:block no-scrollbar ${
        open ? "lg:w-60" : "lg:w-20"
      } lg:overflow-y-auto lg:bg-secondary lg:pb-4 lg:p-5`}
    >
      <div className="flex items-center justify-start h-8 gap-2 shrink-0">
        <img
          className="flex w-auto h-10 p-1 px-2 text-sm font-semibold leading-6 bg-white rounded-md cursor-pointer group gap-x-3 whitespace-nowrap"
          src="https://i.postimg.cc/8Cv31svZ/Screenshot-2023-12-02-133325-removebg-preview.png"
          alt="hissa_logo"
        />
        {open && (
          <img
            className="w-auto h-5"
            src="https://equion-dev.s3.us-west-2.amazonaws.com/logonew.svg"
            alt="hissa_logo"
          />
        )}
      </div>
      <nav>
        <ul className="pt-6">
          {routes
            .filter((route) => !route.disabled)
            .map((item) => (
              <SideNavItem key={item.name} sideNavItem={item} open={open} />
            ))}
        </ul>
      </nav>
    </div>
  );
}
function SideNavItem({
  sideNavItem,
  open,
}: {
  sideNavItem: SideNavItemType;
  open: boolean;
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const expectedRoute = sideNavItem.path.split("/");
  const locationAsList = location.pathname.split("/");

  const hasFormParamOrQueryParam =
    sideNavItem.name === "Modelling"
      ? undefined
      : location.search || location.hash;

  const selected =
    !hasFormParamOrQueryParam &&
    (locationAsList[locationAsList.length - 1] ===
      expectedRoute[expectedRoute.length - 1] ||
      (sideNavItem.childSubRoutes &&
        sideNavItem.childSubRoutes.some((subRoute) =>
          locationAsList.includes(subRoute)
        )));
  return (
    <li onClick={() => navigate(sideNavItem.path)} key={sideNavItem.name}>
      <div
        className={classNames(
          selected
            ? "bg-primary text-white"
            : "text-gray-300 hover:text-white hover:bg-gray-600",
          "group flex gap-x-3 rounded-md p-2 mb-1 text-sm cursor-pointer whitespace-nowrap leading-6 font-semibold"
        )}
      >
        <Icon icon={`${sideNavItem.icon}`} className="w-6 h-6 shrink-0" />
        <span className={`${!open && "hidden"} ease-in duration-300 `}>
          {sideNavItem.name}
        </span>
      </div>
    </li>
  );
}
export default MainSideNavbar;
